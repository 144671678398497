import { useMemo } from 'react';

import { useRequest } from './useRequest';
import { getApiUrl } from '../utils';

export const useDeleteNotification = ({ workflowId }) => {
  const endpoint = useMemo(() => `${getApiUrl()}/notification/${workflowId}`, [workflowId]);
  return useRequest({
    endpoint,
    method: 'DELETE',
    externalTrigger: true,
  });
};
