import React, { useState } from 'react';
import { Box, Grid, Card, useTheme } from '@mui/material';
import { Tabs, Tab } from '../../shared/table/Tabs';
import { NotificationsTableView } from './NotificationsTableView';

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`notifications-tabpanel-${index}`}
      aria-labelledby={`notifications-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

export const NotificationsTable = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const theme = useTheme();
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Card
      sx={{
        background: theme.palette.white.main,
        borderRadius: 0,
        display: 'flex',
        height: '100%',
        border: '1px solid rgba(224, 224, 224, 1)',
      }}
      elevation={2}
    >
      <div style={{ flexGrow: 1 }}>
        <Grid container>
          <Grid item xs={12}>
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              textColor="primary"
              indicatorColor="primary"
              aria-label="Notifications tabs"
            >
              <Tab value={0} label="Create" />
              <Tab value={1} label="Delete" />
            </Tabs>
          </Grid>
          <Grid item xs={12}>
            <TabPanel value={tabIndex} index={0}>
              <NotificationsTableView create />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <NotificationsTableView remove />
            </TabPanel>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
};
