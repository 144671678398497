import { useMemo } from 'react';

import { useRequest } from './useRequest';
import { getApiUrl } from '../utils';

export const useCreateNotification = ({ body }) => {
  const endpoint = useMemo(() => `${getApiUrl()}/notification`, []);

  return useRequest({
    endpoint,
    method: 'POST',
    externalTrigger: true,
    contentType: 'application/json',
    body: JSON.stringify(body),
  });
};
