import { CreateNotificationForm } from './CreateNotificationForm';
import { DeleteNotificationForm } from './DeleteNotificationForm';

export const NotificationsTableView = ({ create, remove }) => {
  return (
    <>
      {create && <CreateNotificationForm />}
      {remove && <DeleteNotificationForm />}
    </>
  );
};
