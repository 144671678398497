/* eslint-disable */

import { useCallback, useEffect, useState, useRef } from 'react';
import { useMsal } from '@azure/msal-react';
import * as R from 'ramda';

import { loginRequest } from '../../config/authConfig';
import { urlParams, getApiUrl } from '../utils';

const fetchCheckedJson = async (url, options = {}) => {
  const res = await fetch(url, options);
  const { ok, status, statusText } = res;
  if (!ok) {
    const error = Error(`Fetch failed: ${status} ${statusText}`);
    error.response = { status, statusText };
    return Promise.reject(error);
  }
  const contentType = res.headers.get('content-type');
  if (contentType && R.includes('application/json')(contentType)) return res.json ? res.json() : {};
  return res;
};

const isNilOrEmpty = R.converge(R.or, [R.isNil, R.isEmpty]);

const parseOptions = ({ method, token, refreshTokenFunc, body, contentType }) => ({
  method,
  headers: {
    ...(contentType && { 'Content-Type': contentType }),
    Accept: 'application/json',
    ...(token && { Authorization: `Bearer ${token}` }),
  },
  ...(!isNilOrEmpty(body) && { body }),
  ...(token && { refreshTokenFunc }),
});

export const useRequest = ({
  endpoint,
  method,
  args = {},
  body,
  attachmentPath,
  contentType,
  includeToken = true,
  externalTrigger = false,
  clearResultAndError = false, // For scenarios where there might be multiple requests in a row and need to be able to tell if its a new request
}) => {
  const { instance, accounts } = useMsal();
  const [loading, setLoading] = useState();
  const [result, setResult] = useState([]);
  const [error, setError] = useState();
  const attachmentPathRef = useRef(attachmentPath);
  const [loadingFiles, setLoadingFiles] = useState(!!attachmentPathRef.current);
  const [files, setFiles] = useState([]);
  const argsRef = useRef(args);

  const fetchToken = useCallback(async () => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };
    return (await instance.acquireTokenSilent(request)).idToken;
  }, [accounts, instance]);

  const fetchFiles = useCallback(
    async (token, notes = []) => {
      const noteIds = notes.map((note) => note.hubspotId); // TODO: Make safer
      const fetchFilesPromises = noteIds.map((noteId) => {
        const attachmentEndpoint = `${getApiUrl()}/attachments/${noteId}`;
        const options = parseOptions({ method: 'GET', token, fetchToken });
        return fetchCheckedJson(attachmentEndpoint, options);
      });
      const fileResults = await Promise.all(fetchFilesPromises);
      return R.flatten(fileResults);
    },
    [fetchToken],
  );

  const performRequest = useCallback(async () => {
    // For scenarios where there might be multiple requests in a row and need to be able to tell if its a new request
    if (clearResultAndError) {
      setResult([]);
      setError(undefined);
    }
    try {
      setLoading(true);
      const token = includeToken ? await fetchToken() : undefined;
      const paramString = R.isEmpty(argsRef.current) ? '' : urlParams(argsRef.current);
      const url = `${endpoint}${paramString}`;
      const options = parseOptions({ method, token, fetchToken, body, contentType });
      const res = await fetchCheckedJson(url, options);
      setResult(res);
      setLoading(false);
      if (attachmentPathRef.current) {
        const fetchedFiles = await fetchFiles(token, R.pathOr([], attachmentPathRef.current)(res));
        setFiles(fetchedFiles);
      }
      setLoadingFiles(false);
    } catch (err) {
      setError(err);
      setLoading(false);
      setLoadingFiles(false);
    }
  }, [
    endpoint,
    method,
    includeToken,
    fetchToken,
    attachmentPathRef,
    fetchFiles,
    body,
    contentType,
  ]);

  useEffect(() => {
    if (!externalTrigger) performRequest();
  }, [performRequest, externalTrigger]);

  return {
    error,
    loading,
    result,
    loadingFiles,
    files,
    ...(externalTrigger && { triggerFunc: performRequest }),
  };
};
