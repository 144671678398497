import { useMemo } from 'react';

import { useRequest } from './useRequest';
import { getApiUrl } from '../utils';

export const useNotifications = () => {
  const endpoint = useMemo(() => `${getApiUrl()}/notifications`, []);
  return useRequest({
    endpoint,
    method: 'GET',
  });
};
