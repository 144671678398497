import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, TextField, Button, Grid, FormHelperText } from '@mui/material';

export const DealDetailsShareModal = ({ open, close, onSend, customUrl, name, result }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [defaultMessage, setDefaultMessage] = useState('');

  const [emailError, setEmailError] = useState('');

  useEffect(() => {
    const customer = result.customer === null ? 'TBD' : result.customer;
    const country = result.country === null ? 'TBD' : result.country;
    const location = result.location === null ? 'TBD' : result.location;
    const assignmentName = result.name === null ? 'TBD' : result.name;
    const description = result.description === null ? 'TBD' : result.description;
    const lastDayForBid = result.lastDayForBid === null ? 'TBD' : result.lastDayForBid;
    const lastDayForApplication =
      result.lastDayForApplication === null ? 'TBD' : result.lastDayForApplication;
    const frameworkAgreement =
      result.frameworkAgreement?.name === null ? 'TBD' : result.frameworkAgreement?.name;
    const msg = `Hi,
This assignment might be of interest for you:

Customer: ${customer}
Framework Agreement: ${frameworkAgreement}
Name: ${assignmentName}
Country: ${country}
Location: ${location}
Last day for application: ${lastDayForApplication}
Last day for bid: ${lastDayForBid}
Link: ${customUrl}
Description: ${description}

Best Regards
${name}`;
    setMessage(msg);
    setDefaultMessage(msg);
  }, [result, name, customUrl]);

  const validateEmail = (inputEmail) => {
    // Attempt to match a valid email string
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(inputEmail);
  };
  const handleClose = () => {
    setEmail('');
    setMessage(defaultMessage);
    setEmailError('');
    close();
  };
  const handleDone = () => {
    handleClose();
  };
  const handleSend = (event) => {
    event.preventDefault();
    if (!email) {
      setEmailError('Email is required');
      return;
    }
    if (!validateEmail(email)) {
      setEmailError('Invalid email address');
      return;
    }
    onSend(email, message);
    handleDone();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        component="form"
        onSubmit={handleSend}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '50%',
          minWidth: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <>
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Share assignment
          </Typography>
          <TextField
            fullWidth
            label="Email *"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError('');
            }}
            sx={{ mb: 2 }}
            error={!!emailError}
          />
          {emailError && <FormHelperText error>{emailError}</FormHelperText>}
          <TextField
            fullWidth
            label="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            multiline
            rows={10}
            sx={{ mb: 2 }}
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button fullWidth variant="contained" type="submit" sx={{ borderRadius: 6 }}>
                Open Mail
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth variant="outlined" onClick={handleClose} sx={{ borderRadius: 6 }}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </>
      </Box>
    </Modal>
  );
};
