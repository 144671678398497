/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Radio,
  Snackbar,
  Alert,
} from '@mui/material';
import * as R from 'ramda';
import { Button } from '../../shared/NavigateButton';
import { useDeleteNotification } from '../../../utils/hooks/useDeleteNotification';
import { useNotifications } from '../../../utils/hooks/useNotifications';

const initialAlertState = { message: '', open: false, variant: 'error' };

export const DeleteNotificationForm = () => {
  const [selectedNotification, setSelectedNotification] = useState('');
  const [alert, setAlert] = useState(initialAlertState);
  const [bpNotificationWorkflows, setbpNotificationWorkflows] = useState([]);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const frequencyMap = {
    onCreation: 'On Assignment Creation',
    weekly: 'Weekly',
    daily: 'Daily',
  };

  const {
    loading: loadingNotifications,
    result: fetchedNotifications,
    error: errorNotifications,
  } = useNotifications();

  const {
    loading: deleteNotificationLoading,
    result: deleteNotificationResult,
    error: deleteNotificationError,
    triggerFunc: deleteNotification,
  } = useDeleteNotification({ workflowId: selectedNotification });

  useEffect(() => {
    if (!R.isNil(fetchedNotifications) && !R.isEmpty(fetchedNotifications)) {
      const { status, workflows } = fetchedNotifications;
      if (status === 'success') {
        setbpNotificationWorkflows(workflows);
      } else {
        setAlert({
          message: 'Failed to fetch assignment notifications',
          open: true,
          variant: 'error',
        });
        setSnackBarOpen(true);
      }
    } else if (!R.isNil(errorNotifications) && !R.isEmpty(errorNotifications)) {
      setAlert({
        message: 'Failed to fetch assignment notifications',
        open: true,
        variant: 'error',
      });
      setSnackBarOpen(true);
    }
  }, [fetchedNotifications, errorNotifications]);

  useEffect(() => {
    if (!R.isNil(deleteNotificationResult) && !R.isEmpty(deleteNotificationResult)) {
      const { status, workflowId } = deleteNotificationResult;
      if (status === 'success') {
        setbpNotificationWorkflows((prevWorkflows) =>
          prevWorkflows.filter((workflow) => workflow.workflowId !== workflowId),
        );
        setAlert({
          message: 'Sucessfully deleted assignment notification',
          open: true,
          variant: 'success',
        });
        setSnackBarOpen(true);
      } else {
        setAlert({
          message: 'Failed to delete assignment notification',
          open: true,
          variant: 'error',
        });
        setSnackBarOpen(true);
      }
      setSelectedNotification('');
    } else if (!R.isNil(deleteNotificationError) && !R.isEmpty(deleteNotificationError)) {
      setAlert({
        message: 'Failed to delete assignment notification',
        open: true,
        variant: 'error',
      });
      setSnackBarOpen(true);
    }
  }, [deleteNotificationResult, deleteNotificationError]);

  const handleDelete = () => {
    if (selectedNotification !== '') {
      deleteNotification();
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{ mt: 2, mb: 2 }}
    >
      <Typography variant="h4" sx={{ mt: 2, mb: 2 }}>
        Delete assignment notification
      </Typography>
      <Typography variant="body1" sx={{ mt: 2, mb: 2 }}>
        Select notification to delete
      </Typography>
      {loadingNotifications ? (
        <CircularProgress sx={{ mt: 2, mb: 2 }} />
      ) : bpNotificationWorkflows && bpNotificationWorkflows.length === 0 ? (
        <Typography variant="body1" sx={{ mt: 2, mb: 2 }}>
          You do not have any notification filters.
        </Typography>
      ) : (
        <>
          <TableContainer component={Paper} sx={{ mt: 2, mb: 2, width: '80%' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Select</TableCell>
                  <TableCell>Keywords</TableCell>
                  <TableCell>Frequency</TableCell>
                  <TableCell>Day</TableCell>
                  <TableCell>Countries</TableCell>
                  <TableCell>Work Models</TableCell>
                  {/* For debugging purposes */}
                  {/* <TableCell>Workflow Id</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {bpNotificationWorkflows &&
                  bpNotificationWorkflows.map((workflow) => (
                    <TableRow key={workflow.workflowId}>
                      <TableCell>
                        <Radio
                          checked={selectedNotification === workflow.workflowId}
                          onChange={(event) => setSelectedNotification(event.target.value)}
                          value={workflow.workflowId}
                        />
                      </TableCell>
                      {/* Present without comma */}
                      <TableCell>{workflow.keywords.split(',').join(' ')}</TableCell>
                      {/* Present original string with commas */}
                      {/* <TableCell>{workflow.keywords}</TableCell> */}
                      <TableCell>{frequencyMap[workflow.frequency]}</TableCell>
                      <TableCell>{workflow.frequency === 'weekly' ? workflow.day : ''}</TableCell>
                      <TableCell>{workflow.countries?.join(', ')}</TableCell>
                      <TableCell>{workflow.workModels?.join(', ')}</TableCell>
                      {/* For debugging purposes */}
                      {/* <TableCell>{workflow.workflowId}</TableCell> */}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              mx: 'auto',
              width: '50%',
              display: 'flex',
              justifyContent: 'space-around',
              mt: 2,
              mb: 2,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              disabled={deleteNotificationLoading}
              onClick={handleDelete}
              sx={{ mt: 2, mb: 2 }}
            >
              Delete
              {deleteNotificationLoading && (
                <CircularProgress
                  size={22}
                  thickness={6}
                  sx={{
                    position: 'absolute',
                    top: '25%',
                    left: '43%',
                  }}
                />
              )}
            </Button>
          </Box>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={snackBarOpen}
            autoHideDuration={10000}
            onClose={() => setSnackBarOpen(false)}
            sx={{ width: '100%' }}
          >
            <Alert onClose={() => setSnackBarOpen(false)} severity={alert.variant}>
              {alert.message}
            </Alert>
          </Snackbar>
        </>
      )}
    </Box>
  );
};
