/* eslint-disable max-lines */
import React, { useState, useEffect, useMemo } from 'react';
import {
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  MenuItem,
  Select,
  FormHelperText,
  CircularProgress,
  FormControl,
  InputLabel,
  Alert,
  Snackbar,
} from '@mui/material';
import * as R from 'ramda';

import { useForm, Controller } from 'react-hook-form';
import { Button } from '../../shared/NavigateButton';
import { useCreateNotification } from '../../../utils/hooks/useCreateNotification';
import { useProperties } from '../../../utils/hooks/useProperties';

const initialAlertState = { message: '', open: false, variant: 'error' };

// eslint-disable-next-line max-lines-per-function
export const CreateNotificationForm = () => {
  const [formError, setFormError] = useState('');
  const [alert, setAlert] = useState(initialAlertState);
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  const {
    watch,
    control,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      countries: [],
      workModels: [],
      keywords: '',
      frequency: 'onCreation',
      day: '',
    },
  });

  const body = watch();

  const isStringValidCommaSeparated = (value) => {
    const trimmedValue = value.trim();
    // Regular expression to validate comma-separated list of single words without spaces
    const regex = /^$|^([^\s,]+)(,[^\s,]+)*$/;
    return regex.test(trimmedValue);
  };

  const {
    loading: countriesLoading,
    result: { property: { options: countryVals = [] } = {} } = {},
  } = useProperties({
    objectType: 'deals',
    propertyName: 'country',
  });

  const {
    loading: workModelsLoading,
    result: { property: { options: workModelsVals = [] } = {} } = {},
  } = useProperties({
    objectType: 'deals',
    propertyName: 'work_model_v2',
  });

  const {
    loading: createNotificationLoading,
    result: createNotificationResult,
    triggerFunc: createNotificationFunc,
    error: createNotificationError,
  } = useCreateNotification({ body });

  const hubspotCountriesVals = useMemo(
    () =>
      countryVals
        .filter((option) => option.label !== '')
        .map((option) => ({
          key: option.label,
          value: option.value,
        })),
    [countryVals],
  );

  const hubspotWorkModelsVals = useMemo(
    () =>
      workModelsVals
        .filter((option) => option.label !== '')
        .map((option) => ({
          key: option.label,
          value: option.value,
        })),
    [workModelsVals],
  );

  useEffect(() => {
    if (!R.isNil(createNotificationResult) && !R.isEmpty(createNotificationResult)) {
      const { status } = createNotificationResult;
      if (status === 'success') {
        setAlert({
          message: 'Successfully created assignment notification',
          open: true,
          variant: 'success',
        });
        setSnackBarOpen(true);
        reset();
      } else {
        setAlert({
          message: 'Failed to create assignment notification',
          open: true,
          variant: 'error',
        });
        setSnackBarOpen(true);
      }
    } else if (!R.isNil(createNotificationError) && !R.isEmpty(createNotificationError)) {
      setAlert({
        message: 'Failed to create assignment notification',
        open: true,
        variant: 'error',
      });
      setSnackBarOpen(true);
    }
  }, [createNotificationResult, createNotificationError, reset]);

  // Clear day errors and set day to empty when frequency changes from weekly to onCreation or daily
  useEffect(() => {
    if (body.frequency !== 'weekly') {
      clearErrors('day');
      setValue('day', '');
    }
  }, [body.frequency, setValue, clearErrors]);

  // eslint-disable-next-line complexity
  const validateRequest = (data) => {
    // Clear general error
    setFormError('');

    // If all fields are empty, do not create workflow
    const emptyCountries = Array.isArray(data.countries) && data.countries.length === 0;
    const emptyWorkModels = Array.isArray(data.workModels) && data.workModels.length === 0;
    const emptyKeywords = data.keywords?.trim().length === 0;

    // If keywords are not empty and has valid string format
    const validKeywords = isStringValidCommaSeparated(data?.keywords);
    // Allow up to 20 keywords
    const validNumberOfKeywords = data?.keywords.split(',').length <= 20;

    // If frequency is weekly and day is not set
    const invalidFrequency = data.frequency === 'weekly' && !data.day;
    if (!validKeywords) {
      setError('keywords', {
        type: 'manual',
        message: 'Please enter a valid comma-separated list of single words without spaces.',
      });
    }
    if (invalidFrequency) {
      setError('day', {
        type: 'manual',
        message: 'Please select a day for weekly notifications.',
      });
    }
    if (!validNumberOfKeywords) {
      setFormError('Too many keywords. (Max 20)');
    } else if (emptyCountries && emptyWorkModels && emptyKeywords) {
      setFormError('Must set at least one of keywords, countries or work models.');
    }
    return (
      validKeywords &&
      !invalidFrequency &&
      validNumberOfKeywords &&
      !(emptyCountries && emptyWorkModels && emptyKeywords)
    );
  };

  const onSubmit = async (data) => {
    if (validateRequest(data)) {
      createNotificationFunc();
    }
  };

  const handleFormSubmit = handleSubmit((data) => {
    onSubmit(data);
  });

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      component="form"
      onSubmit={handleFormSubmit}
      sx={{ mb: 2, mt: 2 }}
    >
      <Typography variant="h4" sx={{ mt: 2, mb: 2 }}>
        Create assignment notification
      </Typography>
      <Typography variant="body1" sx={{ mt: 2, mb: 2 }}>
        Specify which keywords, countries and/or work models you want to receive notifications for
        when any of them appear on an assignment.
        <br />
        If any of these are found on an valid assignment for you, emails will be sent to notify you
        with the selected frequency.
      </Typography>
      {formError && (
        <Typography variant="body2" color="error">
          {formError}
        </Typography>
      )}
      {R.not(
        R.any(R.equals(false))([createNotificationLoading, countriesLoading, workModelsLoading]),
      ) ? (
        <CircularProgress sx={{ mt: 2, mb: 2 }} />
      ) : (
        <>
          <Controller
            name="keywords"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Keywords (optional)"
                placeholder="C++,Norrbotten,Östergötland,Stockholm"
                sx={{ width: '50%', mt: 2, mb: 2 }}
                margin="normal"
                error={!!errors.keywords}
                helperText={errors.keywords?.message}
              />
            )}
          />
          <FormControl control={control} margin="normal" sx={{ width: '50%', mt: 2, mb: 2 }}>
            <InputLabel id="countries-select-label">Countries (optional)</InputLabel>
            <Controller
              name="countries"
              control={control}
              render={({ field }) => (
                <Select
                  multiple
                  labelId="countries-select-label"
                  {...field}
                  label="Select countries to monitor"
                >
                  {hubspotCountriesVals.map((country) => (
                    <MenuItem key={country.key} value={country.value}>
                      {country.key}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
          <FormControl control={control} margin="normal" sx={{ width: '50%', mt: 2, mb: 2 }}>
            <InputLabel id="workModels-select-label">Work models (optional)</InputLabel>
            <Controller
              name="workModels"
              control={control}
              render={({ field }) => (
                <Select
                  multiple
                  labelId="workModels-select-label"
                  {...field}
                  label="Select workModels to monitor"
                >
                  {hubspotWorkModelsVals.map((workModel) => (
                    <MenuItem key={workModel.key} value={workModel.value}>
                      {workModel.key}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
          <>
            <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
              Frequency
            </Typography>
            <Controller
              name="frequency"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field} row margin="normal">
                  <FormControlLabel
                    value="onCreation"
                    control={<Radio />}
                    label="On Assignment Creation"
                  />
                  <FormControlLabel value="daily" control={<Radio />} label="Daily" />
                  <FormControlLabel value="weekly" control={<Radio />} label="Weekly" />
                </RadioGroup>
              )}
            />
            {watch('frequency') === 'weekly' && (
              <Controller
                name="day"
                control={control}
                render={({ field }) => (
                  <>
                    <Select
                      {...field}
                      displayEmpty
                      sx={{ width: '50%', mt: 2, mb: 2 }}
                      error={!!errors.day}
                    >
                      <MenuItem value="" disabled>
                        Select Day
                      </MenuItem>
                      <MenuItem value="Monday">Monday</MenuItem>
                      <MenuItem value="Tuesday">Tuesday</MenuItem>
                      <MenuItem value="Wednesday">Wednesday</MenuItem>
                      <MenuItem value="Thursday">Thursday</MenuItem>
                      <MenuItem value="Friday">Friday</MenuItem>
                      <MenuItem value="Saturday">Saturday</MenuItem>
                      <MenuItem value="Sunday">Sunday</MenuItem>
                    </Select>
                    {errors.day && <FormHelperText error>{errors.day.message}</FormHelperText>}
                  </>
                )}
              />
            )}
          </>
          <Button
            disabled={Object.keys(errors).length > 0 || createNotificationLoading}
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 2, mb: 2 }}
          >
            Create
            {createNotificationLoading && (
              <CircularProgress
                size={22}
                thickness={6}
                sx={{
                  position: 'absolute',
                  top: '25%',
                  left: '43%',
                }}
              />
            )}
          </Button>
        </>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackBarOpen}
        autoHideDuration={10000}
        onClose={() => setSnackBarOpen(false)}
        sx={{ width: '100%' }}
      >
        <Alert onClose={() => setSnackBarOpen(false)} severity={alert.variant}>
          {alert.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};
